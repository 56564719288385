import React from 'react'
import Time from './Time';
import HoverLink from './HoverLink';
import './index.css';
import { isChrome } from 'react-device-detect';

const About = () => {


    return (
        <div style = {{maxWidth: "75%"}}>
            {isChrome ? (
            <div class = "nameBoxDivChrome">
                Sid Bharthulwar
            </div>
            ) : (
                <div class = "nameBoxDiv">
                </div>
            )}

          <Time></Time>

            <p>I study computer science and math at <HoverLink text = "Harvard" routing = "/classes"/>. In the past, I've worked on macro investing at <HoverLink text = "Bridgewater" href = "http://bridgewater.com"></HoverLink>, software engineering at <HoverLink text = "Two Sigma" href = "http://twosigma.com"></HoverLink> and an <HoverLink text = "early-stage AI startup" href = "http://unsupervised.com"></HoverLink>, and research at Harvard, MIT CSAIL, and Penn. </p>
            
            <p>
            Feel free to <HoverLink routing = "/contact" text = "reach out"/> - my DMs are open. </p>
            
            <img src="https://hitwebcounter.com/counter/counter.php?page=7811144&style=0007&nbdigits=5&type=ip&initCount=0" id = "counter" border="0" />

        </div>
    )
}

export default About
